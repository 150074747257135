.sombra-2x {box-shadow: 0 0 2px black;}
.sombra-4x {box-shadow: 0 0 4px black;}
.sombra-8x {box-shadow: 0 0 8px black;}
.sombra-16x {box-shadow: 0 0 16px black;}

.App {
    min-height: 93vh;
}
#root footer {
    position: relative;
    bottom: 0;
    right: 2.5%;
    float: right;
    font-size: 80%;
    font-weight: bold;
    margin-right: 10px;
}

#root footer span {
    font-family: cursive;
    font-size: 1.35em;
    color: firebrick;
    font-weight: bold;
}
/* BOTAO REDONDO COM SOMBRA */ 
.icone-redondo {
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 4px black;
    background: white;
    padding: 3px;
    margin: 5px;
    transition: .2s;
}
.icone-redondo:hover {
    box-shadow: 0 0 8px black;
    transform: scale(1.1,1.1);
}

.icone-gigante {
    font-size: 130px;
}

.botao-flutuar {
    position: fixed;
    right: 5px;
    bottom: 5px;
}

.lista-menu {
    position: fixed;
    top: 5px;
}

@media screen and (max-width:767px){
    #root footer {
        position: relative;
        float: right;
        font-weight: bold;
        margin-right: 5px;
    }
    
    #root footer span {
        font-family: cursive;
        font-size: 1em;
        color: firebrick;
        font-weight: bold;
    }   
}

/* FORMULARIO GERAL DE TODOS OS NOVAS VIEWS DO BI QUE RECUPERAM DADOS */
.form-geral {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.form-geral > button {
    color: white;
    font-weight: bold;
    background: firebrick;
    width: 20%;
    margin-bottom: 5px;
}

/* DISPOSITIVOS MOBILE */
@media screen and (max-width: 767px){
    .form-geral {
        flex-direction: column;
    }

    .form-geral label {
        width: 100%;
    }

    .form-geral > button {
        width: 100%;
    }
}

.bg-white {
    background-color: black;
}
