$sidebar-bg-color: none;
$sidebar-collapsed-width: 80px;
$icon-size: 36px;

$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d9d9d9 !default;
$submenu-bg-color: none !default;
$submenu-bg-color-collapsed: none !default;


$breakpoint-xs: 0px !default;
$breakpoint-sm: 600px !default;
$breakpoint-md: 900px !default;
$breakpoint-lg: 1200px !default;
$breakpoint-xl: 1536px !default;
$breakpoint-xxl: 1600px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';
