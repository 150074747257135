
/** ESTILO DA TABELA */
table {
  background-color: transparent;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}
.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.Tabela {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* TABELA NO GERAL*/
.Tabela table th,
.Tabela table td {
  font-size: 80%;
  padding: 3px;
  vertical-align: middle;
}

/* CABE */
.Tabela .tabela-cabe {
  z-index: 10;
  overflow: hidden;
  padding-bottom: 0;
  box-shadow: 0 1px 4px black;
}
.Tabela .tabela-cabe table {
  margin-bottom: 0;
}

/* CORPO */
.Tabela .tabela-corpo {
  overflow-y: auto;
  margin-bottom: 0;
}

.Tabela .tabela-corpo table {
  margin-bottom: 0;
}

.Tabela .tabela-corpo table tfoot tr th {
  position: sticky;
  bottom: 0;
  z-index: 0;
}

/* QUANDO A TABELA ESTIVER NO MOMENTO DE FILTRAGEM DOS DADOS */
.Tabela .tabela-corpo .pesquisando {
  z-index: 20;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 120%;
  background: rgba(0, 0, 0, 0.6);
}
.Tabela .tabela-corpo .pesquisando .rotacionar {
  border-radius: 50%;
  font-size: 48px;
  animation: rotacionar 1s linear infinite;
}
/* RODAPE */
.Tabela .tabela-rodape {
  overflow: hidden;
  padding: 0;
  box-shadow: 0 1px 4px black;
}
.Tabela .tabela-rodape table {
  margin-bottom: 0;
}

/* AREA DO PAINEL SUPERIOR */
.Tabela .painel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}

.Tabela .painel .filtro {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.Tabela .painel .filtro label {
  display: flex;
  align-items: center;
}

.Tabela .painel .filtro label input {
  border-radius: 10px;
  transition: 0.5s;
}

.Tabela .painel .filtro label input:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0);
}
.Tabela .painel .filtro .info-registros {
  font-weight: bold;
  font-size: 1em;
  margin-right: 10px;
}
/* ESTILO PARA BOTAO DE BAIXAR NA TABELA */
.Tabela .painel > .baixar-em-excel {
  font-weight: bold;
  margin: 5px 0;
  margin-left: 5px;
  transition: 0.3s;
  box-shadow: 0 0 2px black;
}
.Tabela .painel > .baixar-em-excel:hover {
  transform: scale(1, 1);
  box-shadow: 0 1px 4px black;
}
.modal-baixar-tabela .form-tabela-modal {
  display: flex;
  flex-direction: column;
}
.modal-baixar-tabela .form-tabela-modal .Botao {
  position: relative;
  left: 25%;
  width: 50%;
}
/* FORMATACOES @media PARA O PAINEL */
@media screen and (max-width: 468px) {
  .Tabela .painel {
    display: flex;
    flex-direction: column-reverse;
  }
  .Tabela .painel .filtro {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .Tabela .painel .filtro label {
    flex: 1;
    width: 100%;
  }
}

/* CONFIGURACOES GERAIS PARA TD E TH */
.Tabela table th,
.Tabela table td {
  padding: 6px;
  white-space: nowrap;
}
/* FORMATACOES PARA TESTE DE FIXACAO DE COLUNAS Á ESQUERDA*/
.Tabela .fixaCorpo,
.Tabela .fixaCabeRoda {
  position: sticky;
  z-index: 50;
}
.Tabela .fixaCorpo {
  background: white;
}
/* ESTILO PARA O BOTAO QUE EXIBE/OCULTA A TABELA DINAMICA*/
.Tabela tr .coluna-dinamico {
  width: 15px;
  text-align: center;
}
.Tabela tr .coluna-dinamico > i {
  font-size: 1.5rem;
  color: firebrick;
  padding: 0.5px;
  font-weight: bold;
  border-radius: 50%;
  box-shadow: 0px 0 2px black;
  transition: 0.3s all;
}
.Tabela tr .coluna-dinamico > i:hover {
  box-shadow: 0px 0px 4px black;
}
